import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  InTakeService,
  regexValidator,
} from '@common/co/auth/services/in-take.service';
import { InTakeFormSectionModel } from '@common/co/feature/in-take/models/in-take-form-section.model';
import * as _ from 'underscore';
import {
  AthleteInTakeDto,
  CompleteFormSectionCommand,
  CompleteFormSectionResponse,
  FormSectionClient,
  GenderType,
} from '@common/services/co-api-client';
import { NotificationService } from '@common/co/core/services/notification.service';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { Router } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { InTakeFormFieldModel } from '@common/co/feature/in-take/models/in-take-form-field.model';

@Component({
  selector: 'in-take-section',
  templateUrl: './in-take-section.component.html',
  styleUrls: ['./in-take-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InTakeSectionComponent implements OnInit {
  @Input()
  section: InTakeFormSectionModel;
  formGroup: FormGroup;
  fields: {
    [key: string]: any;
  } = {};

  public isLoading: boolean = false;

  get completed(): boolean {
    return _.every(this.section.fields, (f) => f.completed);
  }

  constructor(
    public _inTakeService: InTakeService,
    private _formSectionClient: FormSectionClient,
    private _notificationService: NotificationService,
    private _authorizeService: AuthorizeService,
    private _router: Router,
    private fuseProgressBar: FuseProgressBarService,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.createForm();
  }

  createForm(): FormGroup {
    const map = {};
    for (const field of this.section.fields) {
      this.fields[field.id] = field.answer?.value || field.firstDisplayAnswer;
      map[field.id] = new FormControl(
        this.fields[
          field.id
        ] /*{ value: this.fields[field.id], disabled: field.readonly }*/,
        [
          regexValidator(
            field.caption,
            field.regex,
            field.extraData?.regexValidationError,
          ),
        ],
      );
    }
    return new FormGroup(map);
  }

  async next(): Promise<void> {
    this.fuseProgressBar.show();
    this.isLoading = true;
    try {
      const createAnswers = [];
      const updateAnswers = [];
      for (const field of this.section.fields) {
        const control = this.formGroup.controls[field.id];
        if (
          (field.readonly !== true &&
            control.value !== null &&
            control.value !== undefined &&
            control.value !== field.answer?.value &&
            field.validateOnSave &&
            control.valid === true) ||
          field.validateOnSave === false
        ) {
          const answer = this._inTakeService.prepareAnswer(
            field,
            control.value,
          );
          if ('formFieldId' in answer) {
            createAnswers.push({ fieldVM: field, answer: answer });
          } else {
            updateAnswers.push({ fieldVM: field, answer: answer });
          }
        }
      }
      if (createAnswers.length > 0) {
        await this._inTakeService.createAnswers(createAnswers);
      }
      if (updateAnswers.length > 0) {
        await this._inTakeService.updateAnswers(updateAnswers);
      }
      const canComplete = await this.canComplete();
      this.validateAllFormFields(this.formGroup);
      if (canComplete && this.formGroup.valid) {
        await this._inTakeService.next(this.section);
      }
    } finally {
      this.actualizeProfileInfo(this.section.text, this.section.fields);
      this.fuseProgressBar.hide();
      this.isLoading = false;
    }
    return Promise.resolve();
  }

  private actualizeProfileInfo(
    sectionText: string,
    sectionFields: InTakeFormFieldModel[],
  ): void {
    if (sectionText === 'Personal Information') {
      const foundFirstName = sectionFields.find((el) => {
        return el.extraData.firstDisplayClaim === 'firstName';
      });
      const foundLastName = sectionFields.find((el) => {
        return el.extraData.firstDisplayClaim === 'lastName';
      });
      const foundGender = sectionFields.find((el) => {
        return el.caption === 'Sex';
      });
      if (
        foundFirstName?.answer &&
        foundLastName?.answer &&
        foundGender?.answer
      ) {
        const genderValue: string = foundGender?.answer?.value.toString();
        this._authorizeService.actualizeProfileInfo({
          profileName: `${foundFirstName.answer.value} ${foundLastName.answer.value}`,
          gender: GenderType[genderValue],
        });
      }
    }
  }

  previous(): void {
    this._inTakeService.previous(this.section);
  }

  async goToSignIn(): Promise<void> {
    await this._authorizeService.logout();
    return Promise.resolve();
  }

  async canComplete(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._formSectionClient
        .complete(
          new CompleteFormSectionCommand({ sectionId: this.section.id }),
        )
        .subscribe((response: CompleteFormSectionResponse) => {
          if (!response?.success) {
            //this._notificationService.generateNotification(getSplNotification('error', response?.reason));
          } else {
            this._authorizeService.actualizeProfileInfo({
              inTake: new AthleteInTakeDto({
                sectionId: this.section.id,
                completedAt: new Date(),
              }),
            });
          }
          resolve(response?.success);
        }, reject);
    });
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
