import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';

import { PasswordInputComponent } from './password-input.component';

@NgModule({
  imports: [CommonModule, MatInputModule, MatIconModule, ReactiveFormsModule],
  declarations: [PasswordInputComponent],
  exports: [PasswordInputComponent],
})
export class PasswordInputModule {}
