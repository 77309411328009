<div class="change-password-wrapper solo-column">
  <div class="title-section">
    <h1>
      Change Password
    </h1>
    <p>
      Create a secure and yet memorable password for your account.
    </p>

    <p>
      It must include 8 characters, 1 special character, and 1 number.
    </p>
  </div>
  
  <app-password-input 
    [control]="registerForm.controls['oldPassword']" 
    [label]="'Password'" 
    [displayError]="!registerForm.get('oldPassword').hasError('required') && registerForm.get('oldPassword').hasError('oldPassword')"
    [error]="registerForm.get('oldPassword').getError('oldPassword')">
  </app-password-input>
  
  <app-password-input
    [control]="registerForm.controls['newPassword']"
    [label]="'New Password'"
    [displayError]="!registerForm.get('newPassword').hasError('required') && registerForm.get('newPassword').hasError('newPassword')"
    [error]="registerForm.get('newPassword').getError('newPassword')">
  </app-password-input>
  
  <app-password-input
    [control]="registerForm.controls['confirmPassword']"
    [label]="'Confirm New Password'">
  </app-password-input>

  <button mat-button class="spl-button center mt-16 mb-16"
          (click)="change()"
          [disabled]="!completed" aria-label="Continue" matTooltip="Continue">
    Continue
  </button>
</div>