<div class="title-section">
  <h1>
    Your Password
  </h1>
  <p>
    Create a secure and yet memorable password for your account.
  </p>

  <p>
    It must include 8 characters, 1 special character, and 1 number.
  </p>
</div>

<form [formGroup]="registerForm">
  <app-password-input [control]="registerForm.controls['password']" [label]="'Password'"></app-password-input>
  
  <app-password-input [control]="registerForm.controls['confirmPassword']" [label]="'Confirm Password'"></app-password-input>
</form>

<button mat-button class="spl-button center mt-16 mb-16"
        (click)="next()"
        [disabled]="!completed" aria-label="Continue" matTooltip="Continue">
  Continue
</button>
