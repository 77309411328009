<div class="profile-host">
  <h1>
    Select Profile
  </h1>
  <p>
    Which profile do you want to use?
  </p>

  <mat-selection-list #profiles [multiple]="false" fusePerfectScrollbar
    class='mat-selection-list-wrapper spl-scroll-container' [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
    <mat-list-option *ngFor="let profile of _authorizeService.availableProfiles" [value]="profile">
      <div class="user-profile-line">
        <div class="user-icon-host"> </div> <span class="user-profile-name">{{profile.name}} <span *ngIf="!_authorizeService.singleTenant">({{profile.tenant?.name}})</span></span>
      </div>
    </mat-list-option>
  </mat-selection-list>


  <button mat-button [disabled]="!this.profiles.selectedOptions.selected[0]?.value" class="spl-button full mt-16 mb-16"
    (click)="goToDashboard()" aria-label="Continue To Dashboard" matTooltip="Continue To Dashboard">
    Continue To Dashboard
  </button>

  <button mat-button class="spl-outline-button full mt-16 mb-16" (click)="createAnother()"
    aria-label="Add Another Profile" matTooltip="Add Another Profile">
    Add Another Profile
  </button>

  <button mat-button class="spl-outline-button full mt-16 mb-16 sign-out"
    (click)="goToLogin()"
    aria-label="Sign Out" matTooltip="Sign Out">
    Sign Out
  </button>

  <button mat-button class="spl-outline-button full mt-16 mb-16 delete-account"
    (click)="openDeleteAccountConfirmationDialog()"
    aria-label="Delete Account" matTooltip="Delete Account">
    Delete Account
  </button>

</div>
