<div class="info-table-wrapper">
  <span class="small-title">
    {{ data.title }}
  </span>

  <ng-container *ngIf="!trainingInFuture">
    <div fxLayout="column" fxLayoutAlign="start start" class="info-block">
      <span *ngFor="let indicator of data.indicators">
        <span class="info-title">
          {{ indicator.title }}
        </span> {{ indicator.value }}{{ indicator.measure }}
        <img *ngIf="indicator.delta !== null" [src]="indicator.delta ? 'assets/images/icons/delta-bottom.svg' : 'assets/images/icons/delta-top.svg'">
      </span>
    </div>

    <div class="info-table">
      <div class="table-header">
        <ng-container *ngFor="let component of customComponents">
          <ng-container *ngIf="component.component !== componentType.Undefined || component.scoringType === trainingExerciseScoringType.Sets">
            <span fxFlex="1 1 100%">{{ component.title }}</span>
          </ng-container>
        </ng-container>
      </div>
      <div class="table-body">
        <div *ngFor="let row of customExerciseResults">
          <ng-container *ngFor="let component of customComponents">
            <span fxFlex="1 1 100%" class="info-row"
                  *ngIf="component.component === componentType.Undefined && row.setNumber && component.scoringType === trainingExerciseScoringType.Sets">
              {{ row.setNumber }}
            </span>
            <ng-container *ngIf="component.component !== componentType.Undefined">
              <span fxFlex="1 1 100%" class="info-row" *ngIf="component.component !== componentType.ColorDropDown && component.component !== componentType.ExerciseTimeInput">
                <ng-container>{{row[component.targetField]}}</ng-container>
                <span class="uom" *ngIf="row[component.targetField] !== null && component.uom">{{ component.uom }}</span>
              </span>
                <span fxFlex="1 1 100%" class="info-row" *ngIf="component.component === componentType.ExerciseTimeInput">
                <ng-container>{{row[component.targetField] | msecToTime}}</ng-container>
              </span>
                <span fxFlex="1 1 100%" class="info-row" *ngIf="component.component === componentType.ColorDropDown">
                <span *ngIf="row[component.targetField] !== null" class="color" [ngStyle]="{'background-color': row.code}"></span>
                <span>{{ row.selectedOptionTitle }}</span>
              </span>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="trainingInFuture">
    <span class="description mt-8 mb-8">{{ data.description }}</span>
    <div class="spl-video-wrapper">

      <bitmovin-player-poster *ngIf="!!data.video" [video]="data.video" [poster]="data.poster" [relatedObjectId]="data.id"></bitmovin-player-poster>
      <div *ngIf="!data.video" class="video-wrapper__no-video">Video not avaliable</div>

      <!--
      <video playsinline>
        <source src="./assets/videos/small.mp4#t=0.001" type="video/mp4">
      </video>
      -->
    </div>
  </ng-container>

</div>
