import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CustomDialogComponent } from './custom-dialog.component';
import { MaterialModule } from '@common/co/core/material.module';
import { CustomDialogService } from '@common/shared/components/custom-dialog/custom-dialog.service';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

export const CUSTOM_DIALOG_CONFIG = new InjectionToken('customDialogConfig');

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
  ],
  declarations: [CustomDialogComponent],
  exports: [CustomDialogComponent],
})
export class CustomDialogModule {
  static forRoot(config?: Omit<MatDialogConfig, 'data'>): ModuleWithProviders<any> {
    return {
      ngModule: CustomDialogModule,
      providers: [
        CustomDialogService,
        {
          provide: CUSTOM_DIALOG_CONFIG,
          useValue: config || null
        }
      ],
    };
  }
}
