<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="control" />
  <mat-icon matSuffix class="secondary-text cursor-pointer" (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
  <mat-error *ngIf="control.hasError('required')">
    '{{ label }}' is required
  </mat-error>
  <mat-error *ngIf="!control.hasError('required') && control.hasError('password')">
    {{ control.getError('password') }}
  </mat-error>
  <mat-error *ngIf="
            !control.hasError('required') &&
            control.hasError('passwordsNotMatching')
          ">
    Passwords must match
  </mat-error>
  <mat-error *ngIf="displayError && error">
    {{ error }}
  </mat-error>
</mat-form-field>