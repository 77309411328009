import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  style,
} from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class SplSplashScreenService {
  splashScreenEl: any;
  player: AnimationPlayer;
  navEndSubscription: Subscription;
  /**
   * Constructor
   *
   * @param {AnimationBuilder} _animationBuilder
   * @param _document
   * @param {Router} _router
   */
  constructor(
    private _animationBuilder: AnimationBuilder,
    @Inject(DOCUMENT) private _document: Document,
    private _router: Router,
    private _platformService: PlatformService,
  ) {
    // Initialize
    this._init();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _init(): void {
    // Get the splash screen element
    this.splashScreenEl = this._document.body.querySelector(
      '#fuse-splash-screen',
    );

    // If the splash screen element exists...
    if (this.splashScreenEl) {
      // Hide it on the first NavigationEnd event
      //Moved here to avoid skip evend due Promise resolve
      this.navEndSubscription = this._router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1),
        )
        .subscribe(() => {
          this.navEndSubscription.unsubscribe();
          setTimeout(() => {
            this.hide();
          }, 500);
        });
    }

    this._platformService.isWeb().then((isWeb: boolean) => {
      if (!isWeb) {
        this.hide();
        return;
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show the splash screen
   */
  show(): void {
    this._platformService.isWeb().then((isWeb: boolean) => {
      if (!isWeb) return;

      this.player = this._animationBuilder
        .build([
          style({
            opacity: '0',
            zIndex: '99999',
          }),
          animate('400ms ease', style({ opacity: '1' })),
        ])
        .create(this.splashScreenEl);

      setTimeout(() => {
        this.player.play();
      }, 0);
    });
  }

  /**
   * Hide the splash screen
   */
  hide(): void {
    this.player = this._animationBuilder
      .build([
        style({ opacity: '1' }),
        animate(
          '400ms ease',
          style({
            opacity: '0',
            zIndex: '-10',
          }),
        ),
      ])
      .create(this.splashScreenEl);

    setTimeout(() => {
      this.player.play();
    }, 0);
    if (this.splashScreenEl) {
      this.splashScreenEl.setAttribute('style', 'display:none;');
    }
  }
}
