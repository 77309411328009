<div class="reg-complete">
  <img src="assets/images/register/reg-complete.svg" />
</div>
<div class="title-section">
  <h1 class="text-centered">
    Registration Finished!
  </h1>
  <p class="text-centered">
    Now that you've registered, you need to create a profile for each person using this app.
  </p>
</div>
