<div class="completion-host spl-scroll-container" fusePerfectScrollbar
     [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
  <h1>
    Profile Created!
  </h1>
  <p>
    You’ve successfully created a profile.
  </p>
  <button mat-button
          class="spl-button center mb-16 mt-16"
          (click)="goToDashboard()"
          aria-label="Go To Dashboard"
          matTooltip="Go To Dashboard">
    Go To Dashboard
  </button>

  <button mat-button
          class="spl-outline-button center mb-16 mt-16"
          (click)="createAnother()"
          aria-label="Add Another Profile"
          matTooltip="Add Another Profile">
    Add Another Profile
  </button>
</div>
