import {
  Component,
  ViewEncapsulation,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { Router } from '@angular/router';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { Subject } from 'rxjs';
import { first, skip, takeUntil } from 'rxjs/operators';
import { commonNavigationConfig } from '@common/co/navigation/navigation';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ILoginInfo } from '@common/shared/models/login-info';
import { CustomDialogService } from '@common/shared/components/custom-dialog/custom-dialog.service';
import { UserRegistrationService } from '@common/co/auth/services/user-registration.service';

@Component({
  selector: 'select-profile',
  templateUrl: './select-profile.component.html',
  styleUrls: ['./select-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectProfileComponent implements OnInit, OnDestroy {
  private deleteAccountCancellationTime: number = 5; //5 seconds
  private _unsubscribeAll: Subject<any>;

  constructor(
    public _authorizeService: AuthorizeService,
    private _appBusService: AppBusService,
    private _router: Router,
    private fuseProgressBar: FuseProgressBarService,
    private dialog: MatDialog,
    private customDialogService: CustomDialogService,
    private userRegistrationService: UserRegistrationService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  @ViewChild(MatSelectionList, { static: true })
  profiles: MatSelectionList;

  ngOnInit(): void {
    this._authorizeService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (
          !this._authorizeService.availableProfiles ||
          this._authorizeService.availableProfiles.length == 0
        ) {
          this.goToLogin();
        }
      });
  }

  async createAnother(): Promise<void> {
    await this.userRegistrationService.createAnother();
    return Promise.resolve();
  }

  public openDeleteAccountConfirmationDialog(): void {
    let userName;
    this._appBusService.loginData$
      .pipe(first())
      .subscribe((item: ILoginInfo) => (userName = item?.userName));
    this.customDialogService.showDialog({
      title: `Account "${userName}" will be deleted`,
      description: 'Do you want to continue?',
      cancellationTime: this.deleteAccountCancellationTime,
      confirmAction: () => {
        this.fuseProgressBar.show();
        this._authorizeService
          .deleteAccount()
          .finally(() => this.fuseProgressBar.hide());
      },
    });
  }

  async goToDashboard(): Promise<void> {
    const selectedProfile = this.profiles.selectedOptions.selected[0]?.value;
    if (selectedProfile.id !== this._authorizeService.currentProfileId) {
      await this._authorizeService.setCurrentProfile(selectedProfile);
      this._appBusService.setAppSettings(null);
      this._appBusService.inTakePassed$
        .pipe(skip(1), takeUntil(this._unsubscribeAll))
        .subscribe(async () => {
          await this._router.navigateByUrl(
            commonNavigationConfig.dashboard.url,
            {
              replaceUrl: true,
            },
          );
        });
    } else {
      await this._router.navigateByUrl(commonNavigationConfig.dashboard.url, {
        replaceUrl: true,
      });
    }

    return Promise.resolve();
  }

  public async goToLogin(): Promise<void> {
    this._unsubscribeAll.next();
    await this._authorizeService.logout();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
