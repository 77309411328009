<spl-skeleton [showSkeleton]="insuranceResponsePromise" class="medical-coverage-wrapper" [formGroup]="medicalCoverageForm">
  <ng-container>
    <mat-form-field appearance="outline">
      <mat-label>Carrier</mat-label>
      <input matInput formControlName="carrier"/>
      <mat-error *ngIf="medicalCoverageForm.get('carrier').hasError('required')">
        Carrier is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Insured name</mat-label>
      <input matInput formControlName="insuredName"/>
      <mat-error *ngIf="medicalCoverageForm.get('insuredName').hasError('required')">
        Insured name is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Member ID</mat-label>
      <input matInput formControlName="memberId"/>
      <mat-error *ngIf="medicalCoverageForm.get('memberId').hasError('required')">
        Member ID is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Group number</mat-label>
      <input matInput formControlName="groupNumber"/>
      <mat-error *ngIf="medicalCoverageForm.get('groupNumber').hasError('required')">
        Group number is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Insured date of birth</mat-label>
      <input matInput formControlName="insuredDateOfBirth" [matDatepicker]="datePicker" [max]="today"/>
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
      <mat-error *ngIf="medicalCoverageForm.get('insuredDateOfBirth').hasError('required')">
        Insured date of birth is required
      </mat-error>
    </mat-form-field>
    <div class="insurance-card-wrapper" *ngIf="blobNameFormControl.value">
      <div class="insurance-link" (click)="openInsurance()">
        <mat-icon class="s-28">attach_file</mat-icon>
        <span>Insurance card</span>
      </div>
      <mat-icon class="s-28 remove-button" (click)="removeInsuranceLink()">clear</mat-icon>
    </div>
    <files-uploader
      *ngIf="!blobNameFormControl.value"
      [title]="'Add Insurance card'"
      [accept]="'.png,.jpeg,.jpg'"
      (fileSelected)="fileSelected($event)"
    ></files-uploader>
    <div class="buttons-wrapper">
      <div class="cancel-button" (click)="cancel()">
        <span *ngIf="displayCancelButton">Cancel</span>
      </div>
      <button mat-button class="spl-button" [disabled]="!medicalCoverageForm.valid || loading || !dataWasChanged" (click)="save()">
        <span>Save</span>
        <mat-spinner *ngIf="loading" color="accent" diameter="15"></mat-spinner>
      </button>
    </div>
  </ng-container>
</spl-skeleton>