<div id="register" fxLayout="column">
  <div id="register-form-wrapper" fxLayout="row" fxLayoutAlign="space-around">
    <div id="register-form" [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
      <div class="forms-wrapper">

        <register-default *ngIf="currentStep === stepType.Default" [userSocialLogin]="userSocialLogin"
                          [registerFields]="registerFields"
                          (nextStep)="next($event)"></register-default>
        <ng-container *ngIf="currentStep === stepType.UniqueId">
          <div class="title-section">
            <h1>
              Create An Account
            </h1>
            <p *ngIf="currentRegistrationType === registrationType.UseUniqueId">
              Enter Unique Id and Date of Birth to identify if you are eligible to register
            </p>
            <p *ngIf="currentRegistrationType === registrationType.InvalidEmail" class="important">
              We were unable to verify your email address. Please provide your unique identifier from registration invitation email and date of birth to create account
            </p>
          </div>
          <register-unique-id-info
            [registerFields]="registerFields"
            (nextStep)="next($event)"></register-unique-id-info>
        </ng-container>
        <register-personal-info *ngIf="currentStep === stepType.PersonalInfo"
                          [registerFields]="registerFields"
                          (nextStep)="next($event)"></register-personal-info>
        <register-password *ngIf="currentStep === stepType.Password"
                          [registerFields]="registerFields"
                          (nextStep)="next($event)"></register-password>
        <register-waiver-of-liability *ngIf="currentStep === stepType.WaiverOfLiability"
                          [registerFields]="registerFields"
                          (nextStep)="next($event)"></register-waiver-of-liability>
        <register-complete *ngIf="currentStep === stepType.Complete"></register-complete>

        <span mat-button color="primary" *ngIf="currentStep == stepType.Default && currentRegistrationType !== registrationType.UseUniqueId" class="go-back-button"
              (click)="createUseUniqueId()" aria-label="Return To Sign-in" matTooltip="Create with unique id">
          Create with unique id
        </span>

<!--    Profile is already created we should just navigate user to in-take form    -->
        <button mat-button class="spl-button" *ngIf="currentStep == stepType.Complete"
          (click)="navigateToIntake()" aria-label="Create Profile" matTooltip="Create Profile">
          Create Profile
        </button>

        <div *ngIf="currentStep != stepType.Complete" class="filler">
        </div>
        <div *ngIf="currentStep != stepType.Complete" class="separator">
        </div>

        <span mat-button color="primary" *ngIf="(currentStep != stepType.Default || prevStep) && currentStep != stepType.Complete"
          class="go-back-button" (click)="previous()" aria-label="Go Back A Step" matTooltip="Go Back A Step">
          Go Back A Step
        </span>
        <span mat-button color="primary" *ngIf="currentStep == stepType.Default" class="go-back-button mt-12"
          [routerLink]="'/login'" aria-label="Return To Sign-in" matTooltip="Return To Sign-in">
          Return To Sign-in
        </span>
      </div>
    </div>
  </div>
</div>
