<h1 matDialogTitle>{{ data?.title ? data.title : 'Warning' }}</h1>
<div mat-dialog-content>
  <h4 [innerHTML]="data?.message">
  </h4>
</div>
<div mat-dialog-actions class="pt-24 buttons-container">
  <button *ngIf="!data.hideCancelButton" mat-button (click)="dialogRef.close(false)">{{ data?.cancelButtonText ? data.cancelButtonText : 'Cancel' }}</button>
  <button mat-button class="spl-button mr-16" (click)="dialogRef.close(true)" displayServiceIsUnavailableMessageButton [skip]="!data.displayServiceIsUnavailableMessage">
    {{ data?.continueButtonText ? data.continueButtonText : 'OK' }}
  </button>
</div>
