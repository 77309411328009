import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { SplNavigationService } from '@common/co/core/components/navigation/navigation.service';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router } from '@angular/router';
import { InTakeService } from '@common/co/auth/services/in-take.service';
import {
  commonNavigationConfig,
  INavigationConfig,
  NAVIGATION_CONFIG,
} from '@common/co/navigation/navigation';

@Component({
  selector: 'spl-nav-vertical-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class SplNavVerticalItemComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  classes = 'nav-item';

  @Input()
  item: FuseNavigationItem;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   */

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {SplNavigationService} _splNavigationService
   * @param {AuthorizeService} _authorizeService
   * @param {AppBusService} _appBusService
   * @param {FuseConfigService} _fuseConfigService
   * @param {Router} _router
   */
  constructor(
    @Inject(NAVIGATION_CONFIG) private navigationConfig: INavigationConfig,
    private _changeDetectorRef: ChangeDetectorRef,
    private _splNavigationService: SplNavigationService,
    private _authorizeService: AuthorizeService,
    private _appBusService: AppBusService,
    private _fuseConfigService: FuseConfigService,
    private _router: Router,
    private _inTakeService: InTakeService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation item
    merge(
      this._splNavigationService.onNavigationItemAdded,
      this._splNavigationService.onNavigationItemUpdated,
      this._splNavigationService.onNavigationItemRemoved,
    )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  action(item: FuseNavigationItem): void {
    switch (item.id) {
      case 'sign_out':
        this._authorizeService.logout();
        // this._appBusService
        break;
      case 'edit_profile':
        this._router.navigate([commonNavigationConfig.in_take.url], {
          queryParams: { isEdit: true },
        });
        break;
      case 'switch_profile':
        this._router.navigateByUrl(this.navigationConfig.select_profile.url);
        break;
      default:
        throw new Error(`Not implemented for item with id: ${item.id}`);
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
