<div id="reset-password" fxLayout="column">
  <div
    id="reset-password-form-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      *ngIf="!onload"
      id="reset-password-form"
      [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
    >
      <ng-container *ngIf="!isPasswordReset && !displayBackButton">
        <div class="spl-info-text mb-36">
          Please change your password to a secure and memorable password.
          <br/>
          <br/>
          It must include 8 characters, 1 special character, and 1 number.
        </div>

        <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" />
            <mat-error *ngIf="email.hasError('required')"> Email is required </mat-error>
          </mat-form-field>
  
          <app-password-input [control]="password" [label]="'Password'"></app-password-input>
  
          <app-password-input [control]="passwordConfirm" [label]="'Confirm Password'"></app-password-input>

          <div class='button-wrapper'>
            <button
              mat-button
              class="spl-button m-auto"
              color="accent"
              aria-label="UPDATE PASSWORD"
              (click)="onReset()"
              [disabled]="resetPasswordForm.invalid"
            >
              Update Password
            </button>
          </div>
        </form>
      </ng-container>

      <div class='reset-success' *ngIf="isPasswordReset">
        <div class="logo mb-32">
          <img src='assets/images/logos/cup_logo.svg'>
        </div>

        <div class="spl-info-text mb-32">
          Success!
          <br/>
          <br/>
          You’ve updated your password and can now sign in.
        </div>

        <button
          mat-button
          class="spl-button m-auto"
          color="accent"
          aria-label="GO TO SIGN-IN"
          [routerLink]="'/login'"
        >
          Go To Sign-In
        </button>
      </div>

      <div class='error-code' *ngIf="displayBackButton">
        <div class="logo mb-32">
          <img src='assets/images/logos/spl.png'>
        </div>

        <div class="spl-info-text mb-32">
          Reset password request URL you are trying to use has already been used and can't be used again.
        </div>

        <button
          mat-button
          class="spl-button m-auto"
          color="accent"
          aria-label="Back"
          [routerLink]="'/login'"
        >
          Back
        </button>
      </div>
    </div>
    <mat-spinner *ngIf="onload"></mat-spinner>
  </div>
</div>
