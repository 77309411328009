<div class="buttons-list-wrapper">
  <span *ngIf="title" class="title mb-36">{{ title }}</span>
  <div class="list">
    <ng-container *ngFor="let option of list; let i = index;">
      <span *ngIf="!option.disabled" class="option" [ngClass]="{'inactive': option.inactive}" (click)="selectOption(option, i)">
        <span [innerHTML]="option.title"></span>
      </span>
      <span *ngIf="option.disabled" class="option disabled">
        <span [innerHTML]="option.title"></span>
      </span>
    </ng-container>
  </div>
</div>