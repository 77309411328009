<div id="login" fxLayout="column">
  <div id="login-form-wrapper" fxLayout="row" fxLayoutAlign="space-around">
    <div class="splash">
    </div>
    <div id="login-form" [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
      <div class="filler">
      </div>
      
      <div>
        <div class="logo">
          <img src="assets/images/logos/spl.png" />
        </div>
  
        <!-- <div class="title">LOGIN TO YOUR ACCOUNT</div> -->
  
        <form name="loginForm" [formGroup]="loginForm" novalidate>
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
            <mat-icon matSuffix class="secondary-text">mail</mat-icon>
            <mat-error *ngIf="loginForm.get('email').hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="
              !loginForm.get('email').hasError('required') &&
              loginForm.get('email').hasError('email')
            ">
              Please enter a valid email address
            </mat-error>
          </mat-form-field>
    
          <app-password-input [control]="loginForm.controls['password']" [label]="'Password'"></app-password-input>
    
          <div class="remember-forgot-password" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- <mat-checkbox class="remember-me" aria-label="Remember Me">
              Remember Me
            </mat-checkbox> -->
            <div class="register">
              New user?
              <a [routerLink]="navigationConfig.register.url">
                Register
              </a>
            </div>
      
            <a class="forgot-password" [routerLink]="navigationConfig.forgot_password.url">
              Forgot Password?
            </a>
          </div>
    
          <button mat-button (click)="onLogin()" class="spl-button center pulse-animation" aria-label="Sign In"
                  [disabled]="loginForm.invalid || processLogin" [ngClass]="{'button-in-progress': processLogin}">
            {{ processLogin ? 'Signing In...' : 'Sign In' }}
          </button>
        </form>
  
        <div class="login-error">
          <span *ngIf="!!errorMessage" class="error-text">{{errorMessage}}</span>
        </div>
  
        <div class="filler">
        </div>
        <!--      <div class="separator">-->
        <!--         <span class="text">OR</span> -->
        <!--      </div>-->
        <div class="filler">
        </div>
  
        <!-- <button mat-raised-button class="google"><a [href]="loginGoogleUrl">Sign in with Google</a> Log in with Google</button>
  
        <button mat-raised-button class="facebook">Log in with Facebook</button> -->
        <!-- <div class="google-login">
          <img src="assets/images/logos/google.svg" />
          <a (click)="googleLogin()">Sign in with Google</a>
        </div>
  
        <div class="facebook-login">
          <img src="assets/images/logos/facebook.svg" />
          <a (click)="facebookLogin()">Sign in with Facebook</a>
        </div> -->
  
        <!-- <div class="register" fxLayout="column" fxLayoutAlign="center center">
          <span class="text">Don't have an account?</span>
          <a class="link" [routerLink]="'/pages/auth/register'"
            >Create an account</a
          >
        </div> -->
      </div>

      <span class="version-container">
        {{ appVersion }}
      </span>
    </div>
  </div>
</div>
