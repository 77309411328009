<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- SPL ATHLETES APP LAYOUT -->
<ng-container *ngIf="
    (isAuthenticated$ | async) &&
    (inTakeService.isInTakePassed() | async)
  ">
  <spl-athletes-app></spl-athletes-app>
</ng-container>
<!-- / SPL ATHLETES APP LAYOUT -->

<router-outlet *ngIf="!((isAuthenticated$ | async) && (inTakeService.isInTakePassed() | async))"></router-outlet>

<!-- / THEME OPTIONS PANEL -->
