import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { IPayloadItem } from '@common/co/navigation/navigation';
import {
  APPLICATION_DICTIONARY_SERVICE,
  IApplicationDictionaryService,
} from '@common/services/iapplication-dictionary-service';

@Injectable()
export class BreadcrumbsService {
  private _breadcrumb: string;

  constructor(
    private _appBusService: AppBusService,
    @Inject(APPLICATION_DICTIONARY_SERVICE)
    public _applicationDictionaryService: IApplicationDictionaryService,
    private router: Router,
  ) {
    this._appBusService.subscribeToRouteInfo((data: IPayloadItem) => {
      this.setBreadcrumbs(data);
    });
  }

  private setBreadcrumbs(data): void {
    const navigation = this.router.getCurrentNavigation();
    if (data.breadcrumb) {
      let breadcrumb = data.breadcrumb;
      if (!navigation || !navigation.extras.state) {
        breadcrumb = breadcrumb.replace(/<%.*%>/, '').trim();
        this.breadcrumb = breadcrumb;
      } else {
        this.processState(breadcrumb, navigation.extras.state);
      }
    } else {
      this.breadcrumb = null;
    }
  }

  private processState(breadcrumb, state): void {
    const keys = Object.keys(state);
    keys.forEach((key) => {
      breadcrumb = breadcrumb.replace(`<% ${key} %>`, state[key]);
    });
    this.breadcrumb = breadcrumb;
  }

  private set breadcrumb(value: string) {
    if (!value) {
      this._breadcrumb = null;
      return;
    }
    this._breadcrumb = this._applicationDictionaryService.getTextByTemplate(
      value,
      '%EventType%',
      this._applicationDictionaryService.Training,
    );
  }

  get breadcrumb(): string {
    return this._breadcrumb;
  }
}
