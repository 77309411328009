import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FlexModule } from '@angular/flex-layout';
import { MaterialModule } from '../core/material.module';
import { TabsMenuComponent } from './components/tabs-menu/tabs-menu.component';
import { InfoPanelComponent } from './components/info-panel/info-panel.component';
import { DateAsDayPipe } from './pipes/date-as-day.pipe';
import { LinksMenuComponent } from './components/links-menu/links-menu.component';
import { SplLineChartComponent } from './components/spl-line-chart/spl-line-chart.component';
import { SplChartGaugeComponent } from './components/spl-chart-gauge/spl-chart-gauge.component';
import { SplPieChartComponent } from '@common/co/shared/components/spl-pie-chart/spl-pie-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RouterModule } from '@angular/router';
import { ButtonsMenuComponent } from './components/buttons-menu/buttons-menu.component';
import { ExerciseInfoComponent } from './components/exercise-info/exercise-info.component';
import { ButtonsListMenuComponent } from './components/buttons-list-menu/buttons-list-menu.component';
import { SelectListMenuComponent } from './components/select-list-menu/select-list-menu.component';
import { ColorGradientComponent } from './components/color-gradient/color-gradient.component';
import { ColorGradientBetweenComponent } from './components/color-gradient-between/color-gradient-between.component';
import { SplRadarChartComponent } from './components/spl-radar-chart/spl-radar-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { SwitchParentClassDirective } from './directives/switch-parent-class.directive';
import { SplSkeletonComponent } from './components/skeleton/spl-skeleton/spl-skeleton.component';
import { DefaultSkeletonComponent } from './components/skeleton/views/default-skeleton/default-skeleton.component';
import { TrainingSessionSkeletonComponent } from '@common/co/shared/components/skeleton/views/training-session-skeleton/training-session-skeleton.component';
import { AssessmentHeaderSkeletonComponent } from './components/skeleton/views/assessment-header-skeleton/assessment-header-skeleton.component';
import { InfoBlockSkeletonComponent } from './components/skeleton/views/info-block-skeleton/info-block-skeleton.component';
import { InfoBlockComponent } from './components/info-block/info-block.component';
import { HighlightTextOnClickDirective } from './directives/highlight-text-on-click.directive';
import { BitmovinPlayerComponentModule } from '@common/bitmovin-player';
import { ExercisePreviewComponent } from '@common/co/shared/components/exercise-preview/exercise-preview.component';
import { TestingAssessmentHistoryComponent } from '@common/co/shared/components/testing-assessment-history/testing-assessment-history.component';
import { EventsInfoPanelComponent } from '@common/co/shared/components/events-info-panel/events-info-panel.component';
import { NgxMaskModule } from 'ngx-mask';
import { MsecToTimePipe } from './pipes/msec-to-time-pipe';
import { NotesInfoComponent } from '@common/co/shared/components/notes-info/notes-info.component';

@NgModule({
  declarations: [
    TabsMenuComponent,
    InfoPanelComponent,
    DateAsDayPipe,
    LinksMenuComponent,
    SplLineChartComponent,
    SplChartGaugeComponent,
    SplPieChartComponent,
    LinksMenuComponent,
    ButtonsMenuComponent,
    ExerciseInfoComponent,
    ButtonsListMenuComponent,
    SelectListMenuComponent,
    ColorGradientComponent,
    ColorGradientBetweenComponent,
    SplRadarChartComponent,
    SwitchParentClassDirective,
    SplSkeletonComponent,
    AssessmentHeaderSkeletonComponent,
    InfoBlockSkeletonComponent,
    InfoBlockComponent,
    DefaultSkeletonComponent,
    TrainingSessionSkeletonComponent,
    HighlightTextOnClickDirective,
    ExercisePreviewComponent,
    TestingAssessmentHistoryComponent,
    EventsInfoPanelComponent,
    MsecToTimePipe,
    NotesInfoComponent,
  ],
  imports: [
    NgxChartsModule,
    RouterModule,
    NgChartsModule,
    FuseSharedModule,
    FlexModule,
    MaterialModule,
    BitmovinPlayerComponentModule,
    NgxMaskModule,
  ],
  exports: [
    TabsMenuComponent,
    InfoPanelComponent,
    DateAsDayPipe,
    LinksMenuComponent,
    SplLineChartComponent,
    SplChartGaugeComponent,
    SplPieChartComponent,
    LinksMenuComponent,
    ButtonsMenuComponent,
    ExerciseInfoComponent,
    ButtonsListMenuComponent,
    SelectListMenuComponent,
    ColorGradientComponent,
    ColorGradientBetweenComponent,
    SplRadarChartComponent,
    SwitchParentClassDirective,
    SplSkeletonComponent,
    AssessmentHeaderSkeletonComponent,
    InfoBlockSkeletonComponent,
    InfoBlockComponent,
    DefaultSkeletonComponent,
    TrainingSessionSkeletonComponent,
    HighlightTextOnClickDirective,
    ExercisePreviewComponent,
    TestingAssessmentHistoryComponent,
    EventsInfoPanelComponent,
    MsecToTimePipe,
    NotesInfoComponent,
  ],
  providers: [],
})
export class SharedModule {}
