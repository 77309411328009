<div class="title-section">
  <h1>
    Your Personal Info
  </h1>
  <p>
    Enter your personal information below.
  </p>

  <p class="important">
    If you’re setting this app up for a youth you’ll create a profile for them later in the process.
  </p>
</div>

<form [formGroup]="registerForm">
  <mat-form-field appearance="outline">
    <mat-label>Your First Name</mat-label>
    <input trimOnBlur matInput [formControl]="registerForm.controls['firstName']" />
    <mat-error *ngIf="registerForm.get('firstName').hasError('required')">
      First Name is required
    </mat-error>
    <mat-error *ngIf="
                !registerForm.get('firstName').hasError('required') &&
                registerForm.get('firstName').hasError('firstName')
              ">
      Please enter a valid First Name
    </mat-error>
  </mat-form-field>
  
  <mat-form-field appearance="outline">
    <mat-label>Your Last Name</mat-label>
    <input trimOnBlur matInput [formControl]="registerForm.controls['lastName']" />
    <mat-error *ngIf="registerForm.get('lastName').hasError('required')">
      Last Name is required
    </mat-error>
    <mat-error *ngIf="
                !registerForm.get('lastName').hasError('required') &&
                registerForm.get('lastName').hasError('lastName')
              ">
      Please enter a valid Last Name
    </mat-error>
  </mat-form-field>
  
  <mat-form-field appearance="outline">
    <mat-label>Your Date Of Birth</mat-label>
    <input matInput [formControl]="registerForm.controls['dateOfBirth']" [matDatepicker]="datePicker"
      [max]="maxDateOfBirth" showValidationInfo (blur)="validationDateOfBirth()"/>
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
    <mat-error *ngIf="registerForm.get('dateOfBirth').hasError('required')">
      {{ !registerForm.get('dateOfBirth').hasError('matDatepickerParse') ?
          'Date Of Birth is required' : 'Please enter a valid Date Of Birth'
      }}
    </mat-error>
    <mat-error *ngIf="registerForm.get('dateOfBirth').hasError('dateOfBirthToSmall')">
      Minimal age is 18 years
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Zip Code</mat-label>
    <input trimOnBlur matInput [formControl]="registerForm.controls['zip']" />
    <mat-error *ngIf="registerForm.get('zip').hasError('required')">
      Zip Code is required
    </mat-error>
  </mat-form-field>
</form>

<button mat-button class="spl-button center mt-16 mb-16"
        (click)="next()"
        [disabled]="!completed" aria-label="Continue" matTooltip="Continue">
  Continue
</button>
