<div class="check-unique-id-wrapper">
  <div class="title-section">
    <h1>
      Add Profile
    </h1>
    <p>
      You can link an existing record by using your unique ID
    </p>
  </div>
  <register-unique-id-info [registerFields]="registerFields" (nextStep)="next($event)" [buttonTitle]="'Link Existing'"></register-unique-id-info>
  <span mat-button color="primary" class="go-back-button mb-12"
        (click)="createAnother()" aria-label="Or Create New" matTooltip="Or Create New">
    Or Create New
  </span>
  <span mat-button color="primary" class="go-back-button"
        (click)="goBack()" aria-label="Go Back" matTooltip="Go Back">
    Go Back
  </span>
</div>