<div mat-dialog-content
     class="p-16 pb-0 m-0">
  <h1>
    {{section.name}}
  </h1>
  <p>
    {{section.text}}
  </p>

  <form [name]="section.id"
        [formGroup]="formGroup">
    <in-take-field *ngFor="let field of section.fields"
                   [field]="field"
                   [control]="formGroup.controls[field.id]">
    </in-take-field>

    <button mat-button
            class="spl-button center"
            (click)="next()"
            [disabled]="!completed || isLoading"
            aria-label="Accept"
            [matTooltip]="section.actionText || (section.requiresAccept ? 'Accept' : 'Continue')">
      {{ section.actionText || (section.requiresAccept ? 'Accept' : 'Continue') }}
    </button>

    <button mat-button class="spl-outline-button center mt-16 mb-16" (click)="next()" [disabled]="!completed || isLoading"
      aria-label="Skip" matTooltip="Skip">
      Skip
    </button>

    <div *ngIf="section.canPrevious" class="filler">
    </div>
    <div *ngIf="section.canPrevious" class="separator">
      <!-- <span class="text">OR</span> -->
    </div>
    <div *ngIf="section.canPrevious" class="filler">
    </div>
    <span *ngIf="section.canPrevious"
          mat-button
          color="primary"
          class="go-back-button"
          (click)="previous()"
          aria-label="Go Back A Step"
          matTooltip="Go Back A Step">
      < Go Back A Step
    </span>
    <span *ngIf="section.canBackToSignIn"
          mat-button
          color="primary"
          class="go-back-button"
          (click)="goToSignIn()"
          aria-label="Go Back To Sign In"
          matTooltip="Go Back To Sign In">
      < Go Back To Sign In
    </span>
  </form>
</div>
<div mat-dialog-actions
     class="m-0 p-16"
     fxLayout="row"
     fxLayoutAlign="end center">
  <!-- <button *ngIf="section.requiresAccept" mat-button color="primary" class="mr-8"
      (click)="next('accept')" [disabled]="!completed" aria-label="Accept" matTooltip="Accept">
      ACCEPT
  </button> -->
</div>
