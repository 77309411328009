<div class="events-info-panel-wrapper">
  <spl-skeleton [showSkeleton]="eventsCountResponsePromise" [mode]="skeletonModeType.ByContent" [type]="skeletonType.InfoBlock">
    <info-block [title]="'Total Tests<br/>You’ve Taken'" [value]="totalCount">
    </info-block>
  </spl-skeleton>
  <spl-skeleton [showSkeleton]="eventsCountResponsePromise" [mode]="skeletonModeType.ByContent" [type]="skeletonType.InfoBlock">
    <ng-template [ngIf]="!isTrainingClassAvailable">
      <info-block
        [title]="(applicationDictionaryService.Training | titlecase) + ' Sessions<br/>Until Next test'"
        [value]="trainingSessionsCount">
      </info-block>
    </ng-template>
    <ng-template [ngIf]="isTrainingClassAvailable">
      <info-block
        [title]="'Last Test<br/>Date'"
        [value]="dateOfLastTest ? (dateOfLastTest | date: dateFormat) : 'N/A'">
      </info-block>
    </ng-template>
  </spl-skeleton>
</div>
