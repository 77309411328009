export const locale = {
  lang: 'en',
  data: {
    NAV: {
      APPLICATIONS: 'Applications',
      DASHBOARDS: 'Dashboards',
      CALENDAR: 'Calendar',
      ECOMMERCE: 'Data',
      ACADEMY: 'Academy',
      MAIL: {
        TITLE: 'Mail',
        BADGE: '25',
      },
      MAIL_NGRX: {
        TITLE: 'Mail Ngrx',
        BADGE: '13',
      },
      CHAT: 'Chat',
      FILE_MANAGER: 'File Manager',
      CONTACTS: 'Contacts',
      TODO: 'To-Do',
      SCRUMBOARD: 'Scrumboard',
    },
  },
};
