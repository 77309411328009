import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { RegisterComponent } from '@common/co/auth/components/register/register.component';
import { RegisterCommand } from '@common/services/co-api-client';
import { validateAllFormFields } from '@common/co/core/helpers/validate-all-form-fields';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';
import { AppBusService } from '@common/co/core/services/app-bus.service';

@Component({
  selector: 'register-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PersonalInfoComponent implements OnInit {
  @Input() registerFields: RegisterCommand;
  @Output() nextStep: EventEmitter<RegisterCommand> = new EventEmitter();
  registerForm: FormGroup;

  dateOfBirthToSmallErrorMessage: string =
    'You need to be 18-years or older to create an account. If you’re a youth, ask an adult to register themselves then create a profile for you.';

  get completed(): boolean {
    return (
      this.registerForm.get('firstName').valid &&
      this.registerForm.get('lastName').valid &&
      this.registerForm.get('dateOfBirth').valid &&
      this.registerForm.get('zip').valid
    );
  }

  get maxDateOfBirth(): Date {
    const today = new Date();
    return new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate(),
    );
  }

  constructor(
    public registerComponent: RegisterComponent,
    private _formBuilder: FormBuilder,
    private appBusService: AppBusService,
  ) {}

  ngOnInit(): void {
    this.registerForm = this._formBuilder.group({
      firstName: [this.registerFields.firstName, [Validators.required]],
      lastName: [this.registerFields.lastName, [Validators.required]],
      dateOfBirth: [
        this.registerFields.dateOfBirth,
        [Validators.required, dateOfBirthValidator],
      ],
      zip: [this.registerFields.zip, [Validators.required]],
    });
  }

  validationDateOfBirth(): void {
    if (this.registerForm.get('dateOfBirth').hasError('dateOfBirthToSmall')) {
      this.appBusService.processValidationMessage(
        getSplNotification('info', this.dateOfBirthToSmallErrorMessage),
      );
    }
  }

  next(): void {
    if (this.registerForm.valid) {
      const registerCommand: RegisterCommand = this.registerForm.getRawValue();
      registerCommand.waiverOfLiabilitySignerName = `${registerCommand.firstName} ${registerCommand?.lastName[0]}.`;
      this.nextStep.emit(registerCommand);
    } else {
      validateAllFormFields(this.registerForm);
    }
  }
}

export const dateOfBirthValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (!control || !control.value) {
    return null;
  }

  const dateOfBirth = control.value;
  const today = new Date();

  if (dateOfBirth.value === '') {
    return null;
  }

  if (
    new Date(dateOfBirth) <=
    new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
  ) {
    return null;
  }

  return { dateOfBirthToSmall: true };
};
