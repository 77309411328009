export const locale = {
  lang: 'tr',
  data: {
    NAV: {
      APPLICATIONS: 'Programlar',
      DASHBOARDS: 'Kontrol Paneli',
      CALENDAR: 'Takvim',
      ECOMMERCE: 'Data',
      ACADEMY: 'Akademi',
      MAIL: {
        TITLE: 'Posta',
        BADGE: '15',
      },
      MAIL_NGRX: {
        TITLE: 'Posta Ngrx',
        BADGE: '13',
      },
      CHAT: 'Sohbet',
      FILE_MANAGER: 'Dosya Yöneticisi',
      CONTACTS: 'Kişiler',
      TODO: 'Yapılacaklar',
      SCRUMBOARD: 'Proje',
    },
  },
};
