<div id="forgot-password" fxLayout="column">
  <div
    id="forgot-password-form-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      id="forgot-password-form"
      [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
    >
      <ng-container *ngIf="!isEmailSent">

        <div class="spl-info-text mb-36">
          To reset your password, enter your email or phone and we'll send you a link to change your password.
        </div>

        <form
          name="forgotPasswordForm"
          [formGroup]="forgotPasswordForm"
          novalidate
        >
          <mat-form-field appearance="outline">
            <mat-label>Email Or Phone</mat-label>
            <input matInput formControlName="email" showValidationInfo/>
            <mat-error
              *ngIf="email.hasError('required')"
            >
              Email is required
            </mat-error>
            <mat-error *ngIf="!email.hasError('required') && email.hasError('email')">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="
            email.hasError('required') &&
            !email.hasError('email') &&
            email.hasError('backend')">
              {{ email.getError('backend') }}
            </mat-error>
          </mat-form-field>

          <button
            mat-button
            class="spl-button center mt-16 mb-16"
            color="accent"
            aria-label="REQUEST NEW PASSWORD"
            (click)="onForgot()"
            [disabled]="forgotPasswordForm.invalid"
          >
            Request New Password
          </button>

          <span class='splitter'></span>
        </form>
      </ng-container>
      <div class='check-email' *ngIf="isEmailSent">
        <div class="logo">
          <mat-icon class="mat-accent s-96">email</mat-icon>
        </div>

        <div class="title">Check your email address!</div>

        <div class="subtitle spl-info-text mb-24" fxLayout="column">
          <p>A confirmation e-mail has been sent to <b>{{ email?.value }}</b>.</p>
          <p>
            Check your inbox and click on the "Change password" link to reset your password.
          </p>
        </div>
      </div>
      <div class="login" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>chevron_left</mat-icon><a class="link" [routerLink]="'/login'">Go back to login</a>
      </div>
    </div>
  </div>
</div>
