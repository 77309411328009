<div class="title-section">
  <h1>
    Create An Account
  </h1>
  <p>
    Enter your contact info which will be used for signing in.
  </p>

  <p>
    If you are an adult, please use your own information.
  </p>

  <p class="important">
    You need to be 18-years or older to create an account.
  </p>
</div>

<form [formGroup]="registerForm">
  <mat-form-field appearance="outline">
    <mat-label>Your Email</mat-label>
    <input matInput [formControl]="registerForm.controls['email']" [required]="true" showValidationInfo/>
    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
    <mat-error *ngIf="registerForm.get('email').hasError('required')">
      Email is required
    </mat-error>
    <mat-error *ngIf="!registerForm.get('email').hasError('required') && registerForm.get('email').hasError('email')">
      Email is invalid
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Your Phone</mat-label>
    <input matInput [formControl]="registerForm.controls['phone']" [required]="true" [mask]="'000-000-0000'"
           [validation]="true" placeholder="Ex. (000) 000-0000" showValidationInfo/>
    <mat-icon matSuffix class="secondary-text">phone</mat-icon>
    <mat-error *ngIf="registerForm.get('phone').hasError('required')">
      Phone is required
    </mat-error>
    <mat-error *ngIf="registerForm.get('phone').hasError('required') && registerForm.get('phone').hasError('phone')">
      {{ registerForm.get('phone').getError('phone') }}
    </mat-error>
    <mat-error *ngIf="registerForm.get('phone').hasError('mask') || registerForm.get('phone').hasError('phone')">
      Phone number is invalid
    </mat-error>
  </mat-form-field>
</form>


<button mat-button class="spl-button center mt-16 mb-16"
        (click)="next()"
        [disabled]="!completed" aria-label="Continue" matTooltip="Continue">
  Continue
</button>
