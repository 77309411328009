<form [formGroup]="registerForm">
  <mat-form-field appearance="outline">
    <mat-label>Unique Id</mat-label>
    <input matInput [formControl]="registerForm.controls['uniqueId']" [required]="true" [maxlength]="9" showValidationInfo/>
    <mat-error *ngIf="registerForm.get('uniqueId').hasError('required')">
      Unique ID is required
    </mat-error>
    <mat-error *ngIf="registerForm.get('uniqueId').hasError('pattern')">
      Invalid Unique Id
    </mat-error>
    <mat-error *ngIf="!registerForm.get('uniqueId').hasError('required') && registerForm.get('uniqueId').hasError('uniqueId')">
      {{ registerForm.get('uniqueId').getError('uniqueId') }}
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Your Date Of Birth</mat-label>
    <input matInput [formControl]="registerForm.controls['dateOfBirth']" [matDatepicker]="datePicker" showValidationInfo/>
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
    <mat-error *ngIf="registerForm.get('dateOfBirth').hasError('required')">
      {{ !registerForm.get('dateOfBirth').hasError('matDatepickerParse') ?
      'Date Of Birth is required' : 'Please enter a valid Date Of Birth'
      }}
    </mat-error>
  </mat-form-field>
</form>


<button mat-button class="spl-button center mt-16 mb-16"
        (click)="next()"
        [disabled]="!completed" aria-label="Continue" [matTooltip]="buttonTitle">
  {{ buttonTitle }}
</button>
