import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuthClient,
  CheckUniqueIdCommand,
  CheckUniqueIdResponse,
  RegisterCommand,
  UserRegistrationValidation,
} from '@common/services/co-api-client';
import { validateAllFormFields } from '@common/co/core/helpers/validate-all-form-fields';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { RegExp } from '@common/co/core/app.constants';
import moment from 'moment';
import { LoggingService } from '@common/services/logging.service';
import { DateTimeHelper } from '@common/shared/helpers/date-time.helper';

@Component({
  selector: 'register-unique-id-info',
  templateUrl: './unique-id-info.component.html',
  styleUrls: ['./unique-id-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UniqueIdInfoComponent implements OnInit {
  @Input() registerFields: RegisterCommand;
  @Input() buttonTitle: string = 'Continue';
  @Output() nextStep: EventEmitter<RegisterCommand> = new EventEmitter();

  registerForm: FormGroup;

  get completed(): boolean {
    const res =
      this.registerForm.get('uniqueId').valid &&
      this.registerForm.get('dateOfBirth').valid;
    return res;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _authClient: AuthClient,
    private appBusService: AppBusService,
    private _loggingService: LoggingService,
  ) {}

  ngOnInit(): void {
    this.registerForm = this._formBuilder.group({
      uniqueId: [
        this.registerFields.userRegistrationValidation?.uniqueId,
        [Validators.required, Validators.pattern(RegExp.uniqueId)],
      ],
      dateOfBirth: [
        this.registerFields.userRegistrationValidation?.dateOfBirth
          ? moment(this.registerFields.userRegistrationValidation.dateOfBirth)
          : '',
        [Validators.required],
      ],
    });
  }

  async next(): Promise<void> {
    if (this.registerForm.valid) {
      let dateOfBirth = this.registerForm.get('dateOfBirth')?.value;
      if (!dateOfBirth || !moment.isMoment(dateOfBirth)) {
        this._loggingService.logException(
          new Error(
            `${
              this.registerForm.get('dateOfBirth').value
            } - date value is not valid. Is moment: ${moment.isMoment(
              this.registerForm.get('dateOfBirth').value,
            )}`,
          ),
        );
        this.appBusService.processValidationMessage(
          getSplNotification('error', 'Something went wrong.'),
        );
        return;
      }
      dateOfBirth = dateOfBirth.toDate();
      const dateOfBirthWithAddedOffset =
        DateTimeHelper.getDateWithAddedOffset(dateOfBirth);
      const checkUniqueIdDateOfBirth = await this._authClient
        .checkUniqueId(
          new CheckUniqueIdCommand({
            uniqueId: this.registerForm.get('uniqueId').value,
            dateOfBirth: dateOfBirthWithAddedOffset,
          }),
        )
        .toPromise();

      const respCheckUniqueIdDateOfBirth = new CheckUniqueIdResponse(
        checkUniqueIdDateOfBirth,
      );
      if (!respCheckUniqueIdDateOfBirth?.success) {
        this.appBusService.processValidationMessage(
          getSplNotification('info', respCheckUniqueIdDateOfBirth.reason),
        );
      } else {
        this.nextStep.emit(
          new RegisterCommand({
            userRegistrationValidation: new UserRegistrationValidation({
              uniqueId: this.registerForm.get('uniqueId').value,
              dateOfBirth: dateOfBirth,
            }),
          }),
        );
      }
    } else {
      validateAllFormFields(this.registerForm);
    }
  }
}
