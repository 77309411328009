import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  AuthClient,
  CheckPasswordCommand,
  CheckPasswordResponse,
} from '@common/services/co-api-client';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';
import { NotificationService } from '@common/co/core/services/notification.service';
import { takeUntil } from 'rxjs/internal/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;

  private _unsubscribeAll: Subject<any>;

  get completed(): boolean {
    return (
      this.registerForm.get('oldPassword').valid &&
      this.registerForm.get('newPassword').valid &&
      this.registerForm.get('confirmPassword').valid
    );
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _authClient: AuthClient,
    private notificationService: NotificationService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.registerForm = this._formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required, confirmPasswordValidator]],
    });

    this.registerForm
      .get('newPassword')
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.registerForm.get('confirmPassword').updateValueAndValidity();
      });
  }

  async change(): Promise<void> {
    const formData = this.registerForm.getRawValue();
    const checkPassword = await this._authClient
      .checkPassword(
        new CheckPasswordCommand({
          password: formData.newPassword,
        }),
      )
      .toPromise();

    const respCheckPassword = new CheckPasswordResponse(checkPassword);
    if (!respCheckPassword?.success) {
      const errors = {};
      errors['newPassword'] = 'Password policy error';
      this.registerForm.get('newPassword').setErrors(errors);
    } else {
      this._authClient
        .changePassword(formData)
        .subscribe((respChangePassword) => {
          if (!respChangePassword?.success) {
            const errors = {};
            errors['oldPassword'] = respChangePassword.reason;
            this.registerForm.get('oldPassword').setErrors(errors);
          } else {
            this.notificationService.generateNotification(
              getSplNotification('info', 'Password was changed'),
            );
            this.registerForm.reset();
          }
        });
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('newPassword');
  const passwordConfirm = control.parent.get('confirmPassword');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
