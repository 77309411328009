import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'register-complete',
  templateUrl: './register-complete.component.html',
  styleUrls: ['./register-complete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterCompleteComponent {
}
