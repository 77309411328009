import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@common/co/shared/shared.module';
import { SplCoreModule } from '@common/co/core/core.module';
import { PreferencesRoutes } from '@common/co/feature/preferences/preferences.routes';
import { UserPreferencesComponent } from '@common/co/feature/preferences/components/user-preferences/user-preferences.component';
import { MedicalCoverageComponent } from '@common/co/feature/preferences/components/medical-coverage/medical-coverage.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilesUploaderModule } from '@common/shared/components/files-uploader/files-uploader.module';

@NgModule({
  declarations: [
    UserPreferencesComponent,
    MedicalCoverageComponent
  ],
  imports: [
    PreferencesRoutes,
    SplCoreModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    FilesUploaderModule
  ],
  exports: [RouterModule],
  providers: [],
})
export class PreferencesModule {}
