<mat-toolbar class="p-0" [ngClass]="{'isMobile': mobileLayout}">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="space-between center">

    <div class="logo" fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="start center" *displayOnlyOnDesktop>
      <img src="../../../../../assets/images/logos/logo.png" alt="logo">
    </div>
    
    <div class="mobile-header-wrapper" *displayOnlyOnMobile>
      <div *ngIf="splNavigationService.availableBackButton">
        <div class="back-button" (click)="goBack()">
          <mat-icon>keyboard_arrow_left</mat-icon> Back
        </div>
      </div>
      <div *ngIf="breadcrumbService.breadcrumb" class="breadcrumb">
        <span>{{ breadcrumbService.breadcrumb }}</span>
      </div>
    </div>

    <countdown-panel fxFlex="1 1 100%" fxFlexFill *displayOnlyOnDesktop>

    </countdown-panel>

    <div class="" fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="end center">
      <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('navbar')"
        aria-label="Toggle quick panel">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
